<template>
<Componente_nosotros></Componente_nosotros>
</template>

<script>
import Componente_nosotros from '@/components/nosotros/Componente_nosotros.vue'

export default {
  name:'Nosotros',
  components:{
    Componente_nosotros
  }
}
</script>
