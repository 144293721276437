<template>
    <Componente_noticias></Componente_noticias>
</template>

<script>

import Componente_noticias from '@/components/noticias/Componente_noticias.vue';

export default {
    name:'Noticias',

    components: {
        Componente_noticias
    }
}

</script>