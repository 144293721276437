import { createRouter, createWebHistory } from 'vue-router'
import Inicio from '../views/Inicio.vue'
import Nosotros from '../views/Nosotros.vue'
import Servicios from '../views/Servicios.vue'
import Stack from '../views/Stack.vue'
import Noticias from '../views/Noticias.vue'



const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Inicio
    },
    {
      path: '/inicio',
      name: 'inicio',
      component: Inicio
    },
    {
      path: '/nosotros',
      name: 'nosotros',
      component: Nosotros
     // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },

    {
      path: '/servicios',
      name: 'servicios',
      component: Servicios
    },

    {
      path: '/stack',
      name: 'stack',
      component: Stack
    },

    {
      path: '/noticias',
      name: 'noticias',
      component: Noticias
    }
  ],
})



export default router
