<template>

<!--<div class="">
  <h2>Example of Jumbotron</h2>
  <div class="mt-4 p-5 bg-primary text-white rounded">
    <h1>Jumbotron Example</h1> 
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat..</p> 
    <img src="../../assets/avt_piero.jpeg" class="" />
  </div>
</div>-->
<div class="modo_pc">
<br/>
<div class="text-bg-dark">
  <img src="../../assets/nosotros.jpeg" class="card-img nosotros" alt="...">
  <div class="container mt-5 card-img-overlay"><br/><br/><br/>
    <h2 class="card-title text-center mt-5 mb-5">El Equipo</h2>
    <div class="row text-center">
    <div class="col-md-4">
      <img src="../../assets/avt_hector.png" class="img-fluid avatar" />
      <h4>Héctor Pérez</h4>
      <p class="card-text">Administración de servidores Linux, analisis y diseño de bases de datos, desarrollo con tecnologias como PHP, ReactJS.</p>
    </div>
    <div class="col-md-4">
      <img src="../../assets/avt_piero.jpeg" class="img-fluid avatar" />
      <h4>Piero Cóndor</h4>
      <p class="card-text">Desarrollo de sistemas administrativos, sitios web, aplicaciones web con tecnologías PHP, Javascript, Java, Vue, Quasar.</p>
    </div>
    <div class="col-md-4">
      <img src="../../assets/avt_victor.png" class="img-fluid avatar" />
      <h4>Victor Huayhuapuma</h4>
      <p class="card-text">Desarrollo de interfaces de usuario en sistios webs usando tecnologias como JavaScript, Vue Js, React Js, Ajax, Quasar, Bootstrap.</p>
    </div>
  </div>
  </div>
</div>

  <div class="pt-5 pb-5 bg-dark text-white">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h4 class="text-center text-info">¿QUIÉNES SOMOS?</h4><hr/>
          <p class="texto_justify">Somos un emprendimiento en el campo de la informática integrado por desarrolladores con experiencia en el creación de soluciones web, soluciones móviles y demás proyectos que den valor agregado a los proyectos de nuestros clientes.</p> 
        </div>
        <div class="col-md-6">
          <h4 class="text-center text-info">¿QUÉ HACEMOS?</h4><hr/>
          <p class="texto_justify">Realizamos todos los aspectos del proceso de desarrollo de software, incluyendo tus estrategias de información y negocio. Diseñamos, desarrollamos y brindamos soporte a tus productos de software.</p> 
        </div>
      </div>
    </div>
  </div>


<!--container vision mision-->
  <div class="container-fluid text-center">
  <div class="row">
    <div class="col-md-6" style="padding: 0px;">
      <img src="../../assets/mision.png" class="img-fluid">
    </div>
    <div class="col-md-6 contenedor_mision bg-primary text-white" style="padding: 0px;">
      <div class=" texto_mision">
        <h4>MISIÓN</h4> <hr/>
       <br/> 
       <p class="fs-5">
        Promover el desarrollo y la innovacion tecnologica en nuestros clientes brindando soluciones acorde a sus necesidades para el cumplimiento de sus objetivos.
       </p>
      </div>
    </div>
  </div>

  <div class="row">

    <div class="col-md-6 contenedor_vision text-white" style="padding: 0px; background: #03449f;">
      <div class=" texto_vision">
        <h4>VISIÓN</h4> <hr/>
       <br/> 
       <p class="fs-5">
        Ser una empresa reconocida a nivel nacional por la contribución al progreso tecnologico y por el desarrollo de soluciones a largo plazo.
       </p>
      </div>
    </div>    
    <div class="col-md-6" style="padding: 0px;">
      <img src="../../assets/vision.jpg" class="img-fluid">
    </div>
  </div>
  
  </div><!--fin del container vision mision-->

<div class="bg-dark">
  <div class="container text-center text-white">
    <h4 class="text-info pt-5 pb-3 ">VALORES</h4>
  <div class="row">
  <div class="col-md-4"><br/>
    <img src="../../assets/trabajo_equipo.jpg" class="img-fluid mb-3" alt="...">
    <h4 class="text-info">Trabajo en equipo</h4><hr/>
    <p>
      Promover la competitividad a traves de un grupo humano comprometido en el desarrollo de diversos proyectos.
    </p>
  </div>
  <div class="col-md-4"><br/>
    <img src="../../assets/innovadores.jpg" class="img-fluid mb-3" alt="...">
    <h4 class="text-info">Innovación</h4><hr/>
    <p>
      Impulsar ideas plasmándolas en proyectos que brinden mejores soluciones al cliente.
    </p>
  </div>
  <div class="col-md-4"><br/>
    <img src="../../assets/responsabilidad.jpg" class="img-fluid mb-3" alt="...">
    <h4 class="text-info"> Responsabilidad</h4><hr/>
    <p>
      Consolidar el crecimiento profesional mediante el cumplimiento de las actividades en los plazos establecidos.
    </p>
  </div>
</div>
</div><br/>
</div><!--fin row de valores-->
</div>

<div class="modo_movil">
  <Comp_nosotros_movil></Comp_nosotros_movil>
</div>

  <!--{{ $store.state.str_tech }}-->
</template>
    
    <script>
    import {mapState} from 'vuex'
    import axios from "axios";
import Comp_nosotros_movil from './Comp_nosotros_movil';
export default {
      name: 'Componente_nosotros',
      components:{
    Comp_nosotros_movil
  },

data(){
    return{

    }
  },

  methods: {
    name() {
      
    }
  },

  computed: {
/*    computed_str_tech(){
      return this.$store.state.str_tech;
    },*/

    ...mapState([
      'str_tech'
    ])
  },
    }
    </script>
    <style>
  .avatar{
    width: 130px;
    border-radius:150px;
  }

  .nosotros{
    filter: brightness(35%);/*oscurecer una imagen*/
    border-radius: 0px;
  }

  .texto_justify{
    text-align: justify;
  }

  .contenedor_vision{
        display:flex;
        justify-content: center;
        align-items: center;
    }

    .contenedor_mision{
        display:flex;
        justify-content: center;
        align-items: center;
    }

    .texto_mision, .texto_vision{
      padding-right: 100px;
      padding-left: 100px;
    }


@media screen and (min-width: 701px) {/*supera los 700 px modo pc*/
  .modo_movil{
      display: none;
  }

  .modo_pc{
    display: block;
  }
}

@media screen and (max-width: 700px) {/*maximo llega a 700 px modo movil*/
  .modo_movil{
      margin-top: 100px;
      display: block;
  }

  .modo_pc{
    display: none;
  }
}
  </style>