<template>
        <br><br><br>

        <div class="container">
            <div class="tabs-chose">
                <button class="btn btn-primary btn-sm" @click="activeTab = '1'" :class="[activeTab === '1' ? 'active' : '']">Soluciones Web</button>
                <button class="btn btn-primary btn-sm" @click="activeTab = '2'" :class="[activeTab === '2' ? 'active' : '']">Solciones Moviles</button>
                <button class="btn btn-primary btn-sm" @click="activeTab = '3'" :class="[activeTab === '3' ? 'active' : '']">UI/SEO</button>
            </div>

            <div class="tabs-content">
                <div class="content-one" v-if="activeTab === '1'">
                  <Servicio1></Servicio1>
                </div>
                <div class="content-two" v-if="activeTab === '2'">Solciones Moviles</div>
                <div class="content-three" v-if="activeTab === '3'">UI/SEO</div>
            </div>
        </div>
    <button @click="click()">saludo +</button> <br>
    {{ $store.state.str_tech }}    
</template>
    
<script>
import axios from "axios";
import Servicio1 from "./Servicio1";
export default {
 name: 'Componente_servicios',
 components:{
    Servicio1
 },

data(){
    return{
        saludo: "hola",
        activeTab : "1"
    }
},
  
methods: {
    click() {
        alert(this.saludo);
    },
  },
    }
</script>
    
<style>
</style>