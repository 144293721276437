import { createStore } from 'vuex'

export default createStore({
  state: {
    str_tech:"techdevsperu",
    str_nombre:"victor",

    bool_disabled_btn_form:true,
    
    int_IndexNoticia_escogida:'',
    str_noticia:'',
    str_titulo_noticia:'',
    str_autor_noticia:'',
    str_fecha_noticia:'',
    str_imagen_noticia:'',
    noticias:[
      {
        id:"1",
        noticia:"El 25 de agosto de este 2021 se cumplieron 30 años desde que se habló por primera vez del, ahora muy famoso, sistema operativo Linux. Allá por el año 1991 el joven finlandés desarrollador de software, Linus Torvalds que en aquel entonces apenas superaba los 20 años, dio a conocer la existencia de este proyecto en el que estaba trabajando para luego, unas semanas más tarde, a mediados de setiembre, lanzar el código fuente del nuevo sistema operativo. Posteriormente en el año 1992 este sistema operativo fue publicado con una licencia de código abierto gracias a lo cual diferentes desarrolladores de distintas partes del mundo pudieron contribuir al desarrollo de este proyecto que con el paso del tiempo ha ido derivando en lo que se ha llamado nuevas distribuciones del sistema operativo Linux entre las cuales seguramente hemos escuchado mencionar a Debian, Ubuntu, Fedora, Red Hat, Linux Mint y muchas otras más distribuciones creadas con alguna finalidad específica pero con algo en común: todas comparten , como si de un árbol se tratara, una raiz u origen común, el núcleo llamado Linux y cada nueva distribución ha nacido de esta raiz o bien desde una rama que creció desde dicha raiz. ¡Feliz aniversario Linux!.",
        titulo_noticia:"Treinta años de Linux",
        autor_noticia:"Hector",
        fecha_noticia:"2021-09-05",
        imagen_noticia:require("@/assets/logo.png"),
        estado:true
      },

      {
        id:"2",
        noticia:"Si tienes una idea o un proyecto que quieres mostrar al publico a través de Internet, es decir, un sitio web propio, esta es una pregunta que se seguramente te harás pero probablemente, al mismo tiempo apareceran una serie de dudas relacionadas a si será muy difil o complejo lograrlo. Estas dudas en algunos casos podrian llegar a hacerte desistir, truncando ese proyecto que deseabas emprender. {IMAGEN} En la actualidad existen muchas opciones para lograr tener un sitio web propio (de las cuales hablaresmo posterirormente en otros artículos), sin embargo, es bueno tener una idea de lo que necesitas tener para que un sitio web funcione y este disponible para el publico al que quieres mostarle algun tipo de contenido como un producto, algún servicio, noticias, cursos, etc. Basicamente, sin entrar en detalles tecnicos propios de un programador o algún especialista en estás tecnologías, lo que requieres son tres cosas: {Lista: 1.} Un sitio web ya desarrollado. Que es todo el conjunto de codigos, imagenes, textos o demás elementos que se muostrarn en tu sitio web. {Lista 2.} Un alojamiento web. Que es el lugar donde se guarda el sitio web con todos sus componentes desde donde puede ser visitado por todas las personas que lleguen a él. {Lista 3.} Un dominio web. Que es el nombre con el cual se identificará tu sitio web y el nombre que será compartido para que accedan a tu sitio web.",
        titulo_noticia:"¿Qué se necesita para tener una web?",
        autor_noticia:"Hector",
        fecha_noticia:"2022-02-08",
        imagen_noticia:require("@/assets/td.png"),
        estado:true
      },

      {
        id:"3",
        noticia:"Un sitio web nos permite alcanzar más gente en un tiempo corto y con costos bajos comparados a otros medios. Si queremos vender algo, mostrar lo que tenemos para ofrecer, que nos vean gente que se encuentran lejos de donde vivimos; una web es una muy buena solución para realizar estos objetivos.",
        titulo_noticia:"Razones por las que nos conviene tener un Sitio Web",
        autor_noticia:"Hector",
        fecha_noticia:"2022-08-30",
        imagen_noticia:require("@/assets/logo.png"),
        estado:true
      },

      {
        id:"4",
        noticia:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Et",
        titulo_noticia:"Lorem",
        fecha_noticia:"2023-08-30",
        imagen_noticia:require("@/assets/td.png"),
        estado:true
      },
    ]

  },
  getters: {
  },
  mutations: {
    ['MUTATION_EXPANDIR_NOTICIA'] (state){
      state.int_IndexNoticia_escogida;
      state.noticias.map((item, index) =>{
        if (state.int_IndexNoticia_escogida == index) {
          this.state.str_noticia = item.noticia
          this.state.str_titulo_noticia = item.titulo_noticia
          this.state.str_autor_noticia = item.autor_noticia
          this.state.str_fecha_noticia = item.fecha_noticia
          this.state.str_imagen_noticia = item.imagen_noticia
        }
        
      })
      console.log("soy noticia expandida" + state.int_IndexNoticia_escogida)
    }
  },
  actions: {
    acction_expandirNoticias : function({commit}) {
      commit('MUTATION_EXPANDIR_NOTICIA');
    }
  },
  modules: {
  }
})
