<template>
    Solcuiones Web

<div class="row">
    <div class="col-md-6 contenedor_servicios" style="padding: 0px;">
      <div class="p-3">
        <h4>DASHBOARD</h4> <hr/>
       <p class="texto_servicios">
        Un Dashboard también, conocido como cuadro de mando, es un elemento que puedes incluir en tu página, sitio o aplicación con el que podrás ver indicadores de rendimiento o la evolución de tu negocio, empresa o el emprendimiento que estés desarrollando. Dichos indicadores te muestran el estado de tu proyecto de manera más clara y te permiten tomar decisiones que te lleven por buen camino.
       </p>
      </div>
    </div>    
    <div class="col-md-6" style="padding: 0px;">
      <img src="../../assets/servicios/aplica_webs.png" class="img-fluid">
    </div>
  </div>
<br/>
  <div class="row">
    <div class="col-md-6 contenedor_servicios" style="padding: 0px;">
      <div class="p-3">
        <h4>SOLUCIONES DE ACUERDO A TUS NECESIDADES</h4>
        <h4>SOLUCIONES DE ACUERDO A TUS NECESIDADES</h4>  <hr/>
       <p class="texto_servicios">
        Si buscas tener un sitio web o una aplicación que se ajuste a tus necesidades entonces será necesario primero identificar dichas necesidades, esto se logra reconociendo los requerimientos que tiene tu proyecto para después convertirlos en elementos que integren tu web o aplicación. Estos pasos son muy importantes para la materialización de tu idea ya que al hacerlos correctamente, transmitirán lo que quieres comunicar a tu publico en una web o realizarás mejor tus operaciones en una aplicación.
       </p>
      </div>
    </div>    
    <div class="col-md-6" style="padding: 0px;">
      <img src="../../assets/servicios/sistios_web_a_medida.png" class="img-fluid">
    </div>
  </div>
  <br/>
  <div class="row">
    <div class="col-md-6 contenedor_servicios" style="padding: 0px;">
      <div class="p-3">
        <h4>SITIOS WEB ANIMADOS Y DINÁMICOS</h4> <hr/>
       <p class="texto_servicios">
        Una web con estilos frescos, atractivos y con un comportamiento dinámico capta mejor la atención de los visitantes. Una web con estas características se logra no sólo identificando los requerimientos sino aplicando tecnologías para controlar los estilos, la interfaz o la interactividad; todo esto es posible gracias al uso de tecnologías como CSS, Javascript, Bases de Datos y otras tecnologías. Con todo esto tu web contará con una interfaz amigable mejorando la experiencia del usuario y la interacción de los visitantes.
       </p>
      </div>
    </div>    
    <div class="col-md-6" style="padding: 0px;">
      <img src="../../assets/servicios/tecnologias-de-desarrollo-web.jpg" class="img-fluid">
    </div>
  </div>
  <br/>
  <div class="row">
    <div class="col-md-6 contenedor_servicios" style="padding: 0px;">
      <div class="p-3">
        <h4>RESPONSIVE WEB DESIGN</h4> <hr/>
       <p class="texto_servicios">
        Los tamaños de los dispositivos desde donde se accede a un sitio web son variados, debido a esto un sitio web debe estar diseñado de tal forma que su contenido se adapte a cualquier dispositivo conservando su fácil visualización. Nosotros te ofrecemos un proyecto que cumpla con esta característica para que tus clientes encuentren rápidamente lo que buscan.
       </p>
      </div>
    </div>    
    <div class="col-md-6" style="padding: 0px;">
      <img src="../../assets/servicios/que-es-responsive-web-design.jpg" class="img-fluid">
    </div>
  </div>
  <br/>
  <div class="row">
    <div class="col-md-6 contenedor_servicios" style="padding: 0px;">
      <div class="p-3">
        <h4>SEO</h4> <hr/>
       <p class="texto_servicios">
        Si tu objetivo es que tu sitio web logre destacar en Internet ubicandose entre los primeros resultados cuando un cliente busca en la web productos que afreces, entonces podemos ayudarte. Para lograr este objetivo existen muchas tecnicas que en conjunto se denominan SEO (del inglés Search Engine Optimization) o posicionamiento en buscadores, en español. Con estas herramientas podemos hacer que figures entre los primeros resultados en Internet de tal forma que mas personas vean tus productos o servicios.
       </p>
      </div>
    </div>    
    <div class="col-md-6" style="padding: 0px;">
      <img src="../../assets/servicios/optimizacion_SEO.jpg" class="img-fluid">
    </div>
  </div>

</template>

<script>

import axios from "axios";

export default {
    name:'Servicio1',

    data(){
        return{

        }
    },

    methods: {
        name() {
            
        }
    },
}

</script>

<style>
    .contenedor_servicios{
        display:flex;
        justify-content: center;
        align-items: center;
    }

    .texto_servicios{
      text-align: justify;
    }
</style>