<template>
    <footer class="bg_fondo text-white">
        <div class="container">
            <div class="row pt-5 pb-5">
                <div class="col-md-4 col_footer">
                   <div class="texto_colun">
                    <h4 class="text_titulo">TE OFRECEMOS</h4><br/>
                    Soluciones informáticas que necesitas para alcanzar tus metas, te invitamos a revisar nuestra Web en la que encontrarás más información de nosotros, nuestro stack tecnológico y más. Contáctate con nosotros a través de nuestros canales de contacto.
                   </div>                 
                </div>
                <div class="col-md-4 col_footer">
                    <div class="texto_colun">
                        <h4 class="text_titulo">STACK TÉCNOLOGICO</h4><br/>
         <center>                  
        <li class="list-inline-item">
        <a class="btn-floating btn-lg rgba-white-slight mx-1 btn text-info">
          <i class="fab fa-html5"></i>
        </a>
        </li>
        <li class="list-inline-item">
        <a class="btn-floating btn-lg rgba-white-slight mx-1 btn text-info">
          <i class="fab fa-js"></i>
        </a>
        </li>
        <li class="list-inline-item">
        <a class="btn-floating btn-lg rgba-white-slight mx-1 btn text-info">
          <i class="fab fa-css3"></i>
        </a>
        </li>
        <li class="list-inline-item">
        <a class="btn-floating btn-lg rgba-white-slight mx-1 btn text-info">
          <i class="fab fa-php"></i>
        </a>
        </li>
        <li class="list-inline-item">
        <a class="btn-floating btn-lg rgba-white-slight mx-1 btn text-info">
          <i class="fab fa-git"></i>
        </a>
        </li>
        <li class="list-inline-item">
        <a class="btn-floating btn-lg rgba-white-slight mx-1 btn text-info">
          <i class="fab fa-gitlab"></i>
        </a>
        </li>
        <li class="list-inline-item">
        <a class="btn-floating btn-lg rgba-white-slight mx-1 btn text-info">
          <i class="fab fa-vuejs"></i>
        </a>
        </li>
        <li class="list-inline-item">
        <a class="btn-floating btn-lg rgba-white-slight mx-1 btn text-info">
          <i class="fab fa-react"></i>
        </a>
        </li>
        <li class="list-inline-item">
        <a class="btn-floating btn-lg rgba-white-slight mx-1 btn text-info">
          <i class="fab fa-angular"></i>
        </a>
        </li>
        <li class="list-inline-item">
        <a class="btn-floating btn-lg rgba-white-slight mx-1 btn text-info">
          <i class="fab fa-linux"></i>
        </a>
        </li>
        <li class="list-inline-item">
        <a class="btn-floating btn-lg rgba-white-slight mx-1 btn text-info">
          <i class="fab fa-java"></i>
        </a>
        </li>
        <li class="list-inline-item">
        <a class="btn-floating btn-lg rgba-white-slight mx-1 btn text-info">
          <i class="fab fa-bootstrap"></i>
        </a>
        </li>
   <br/>
        <li class="list-inline-item" @click="stack()">
           <router-link to="/stack" :class="[ 'nav-link text-primary btn-floating btn-sm rgba-white-slight mx-1 btn']">Ver más...</router-link>
        </li><br/><br/>
       </center>      
             </div>
                </div>
                <div class="col-md-4 col_footer">
                    <div class="texto_colun">
                        <h4 class="text_titulo">CONTÁCTANOS</h4><br/>
                        <i class="fas fa-envelope mr-3"></i>  informes@techdevsperu.com <br/> <br/>
                        <i class="fa fa-phone mr-3"> 948 619 212</i>
                    </div>
                </div>
            </div>

            <div class="row d-flex aling item center">
                <div class="col-md-7 col-lg-8">
                    © 2022 - TechDevsPerú
                    <p>v3</p>
                </div>
                <div class="col-md-5 col-lg-4 ml-ml-8">
                    <div class="text-center text-md-right">
                        <ul class="list-unstyled list-inline">
                            <li class="list-inline-item">
                              <a href="https://www.facebook.com/techdevsperu" target="_blank" class="btn-floating btn-md rgba-white-slight mx-1 btn btn-info">
                                <i class="fab fa-facebook-f"></i>
                              </a>
                            </li>
                              <li class="list-inline-item">
                                 <a href="https://www.youtube.com/channel/UC0n1le2KqkxVGhXecDYo7Nw" target="_blank" class="btn-floating btn-md rgba-white-slight mx-1 btn btn-info">
                                   <i class="fab fa-youtube"></i>
                                 </a>
                              </li>
                              <li class="list-inline-item">
                                 <a href="https://www.linkedin.com/company/75747446/admin/" target="_blank" class="btn-floating btn-md rgba-white-slight mx-1 btn btn-info">
                                    <i class="fab fa-linkedin-in"></i>
                                 </a>
                              </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import axios from "axios";

export default {
    name:"Componente_footer",

    data() {
        return {
            
        }
    },

    methods: {
        stack() {
            
        }
    },
}
</script>

<style>
.col_footer{
    text-align: justify;
}

.texto_colun{
/*color: #1387ce;*/
/*padding-left: 10px;
padding-right: 10px;*/
}

.text_titulo{
   /* color: #1387ce;*/
   color: white;
}

.bg_fondo{
    background: #0e0c19;
}
</style>
