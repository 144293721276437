<template>
      <nav class="navbar navbar-expand-lg fixed-top bg_menu pb-2 pt-2 menu_border"  v-for="pagina in str_paginas" :key="pagina.str_url1">
  <div class="container">
    <router-link to="/" class="nav-link text-white" @click="home()">
      <div data-bs-toggle="collapse" >
        <img src="../../assets/td.png" class="logo">
      </div>
    </router-link>
    
    <!---<button class="navbar-toggler btn_ambur" type="button" @click="btn_bars()" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <i :class="['fas fa-bars', btnBars ? 'd-block icono_bars' : 'd-none']"></i> <i :class="['fas fa-times', btnBars ? 'd-none' : 'd-block icono_x']"></i>
    </button>-->
    <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="toggler-icon top-bar"></span>
          <span class="toggler-icon middle-bar"></span>
          <span class="toggler-icon bottom-bar"></span>
        </button>
    <div class="collapse navbar-collapse " id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0 fs-5">
        <li class="nav-item text-center border_lista_inicio">
          <router-link 
           to="/inicio" 
           :class="['nav-link text-white hover_inicio']">
           <div data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">{{ pagina.seccion1 }}</div>
          </router-link>
        </li>

        <li class="nav-item text-center border_lista_nosotros">
          <router-link 
          to="/nosotros"  
          :class="['nav-link text-white hover_nosotros', nosotros_bold]" 
          @click="nosotros()">
          <div data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">{{ pagina.seccion2 }}</div>
          </router-link>
        </li>

        <li class="nav-item text-center border_lista_servicios">
          <router-link 
          to="/servicios" 
          :class="['nav-link text-white hover_servicios', servicios_bold]" 
          @click="servicios()">
          <div data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"> {{ pagina.seccion3 }}</div>
        </router-link>
        </li>

        <li class="nav-item text-center border_lista_stack">
          <router-link 
          to="/stack" 
          :class="['nav-link text-white hover_stack', stack_bold]" 
          @click="stack()">
           <div data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">{{ pagina.seccion4 }}</div>
        </router-link>
        </li>

        <li class="nav-item text-center ">
          <router-link 
          to="/noticias" 
          :class="['nav-link text-white hover_noticias', noticias_bold]" 
          @click="noticias()">
          <div data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">{{ pagina.seccion5 }}</div>
        </router-link>
        </li>

      </ul>
      <form class="d-flex dropdown" role="search" v-on:submit.prevent>
        <input type="search" class="form-control input_buscar" placeholder="¿Qué buscamos hoy?" data-bs-toggle="modal" data-bs-target="#buscadorInternoModal">
        <span class="input-group-text bg_lupa"  data-bs-toggle="modal" data-bs-target="#buscadorInternoModal"><i class="fas fa-search text-white"></i></span>
      </form>
    </div>
  </div>
</nav>
      <!-- Button trigger modal -->

<!-- Modal -->
<div class="modal fade" id="buscadorInternoModal" tabindex="-1" aria-labelledby="buscadorInternoModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="buscadorInternoModalLabel">TechDevsPeru</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <input class="form-control me-2" v-model="str_buscar" type="search" placeholder="¿Qué bucamos hoy?" aria-label="Buscar">
      <ul class="list-group list-group-flush">
        <li class="list-group-item" v-for="opcion in filteredAndSorted" :key="opcion.buscador" v-show="str_buscar !='' ">
            <router-link v-bind:to="opcion.buscador" :class="['nav-link']"><i class="fas fa-search"></i> {{ opcion.buscador }}</router-link>
        </li>
      </ul>
      </div>
      <div class=" bg_footer">
         <div class="row  mt-3 mb-3">
           <div class="col-md-5 text-white text-center"><i class="fab fa-whatsapp"></i> 948 619 212</div>
           <div class="col-md-7 text-white text-center"><i class="fas fa-envelope"></i> informes@techdevsperu.com</div>
         </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
    name:'Componente_cabcera_movil',

    data(){
        return{
            str_paginas:[
      {
        str_url1:"inicio",
        str_url2:"nosotros",
        str_url3:"servicios",
        str_url4:"stack",
        str_url5:"noticias",
        
        seccion1: "INICIO",
        seccion2: "NOSOTROS",
        seccion3: "SERVICIOS",
        seccion4: "STACK",
        seccion5: "NOTICIAS"
      
      }
      ],
////
      str_buscar:'',
      str_buscador:[
      {buscador:"inicio"},
      {buscador:"nosotros"},
      {buscador:"servicios"},
      {buscador:"stack"},
      {buscador:"noticias"}
      ],

      btnBars:true
        }
    },
    methods: {

buscar() {
  console.log(this.str_buscador);
    },

home(){
  this.inicio_bold = '';
  this.nosotros_bold = '';
  this.servicios_bold = '';
  this.stack_bold = '';
  this.noticias_bold = '';
},

inicio() {
  this.inicio_bold = 'fw-bold';
  this.nosotros_bold = '';
  this.servicios_bold = '';
  this.stack_bold = '';
  this.noticias_bold = '';
},

nosotros() {
  this.inicio_bold = '';
  this.nosotros_bold = 'fw-bold';
  this.servicios_bold = '';
  this.stack_bold = '';
  this.noticias_bold = '';
},

servicios() {
  this.inicio_bold = '';
  this.nosotros_bold = '';
  this.servicios_bold = 'fw-bold';
  this.stack_bold = '';
  this.noticias_bold = '';
},

stack() {
  this.inicio_bold = '';
  this.nosotros_bold = '';
  this.servicios_bold = '';
  this.stack_bold = 'fw-bold';
  this.noticias_bold = '';
},

noticias() {
  this.inicio_bold = '';
  this.nosotros_bold = '';
  this.servicios_bold = '';
  this.stack_bold = '';
  this.noticias_bold = 'fw-bold';
},

/*btn_bars(){
this.btnBars = !this.btnBars;
}*/
},

computed: {
filteredAndSorted() {//buscador
  const compare = (a, b) => {
  if (a.buscador < b.buscador) return -1;
  if (a.buscador > b.buscador) return 1;
  return 0;
};
return this.str_buscador.filter((opcion) => {
    return opcion.buscador.toLowerCase().includes(this.str_buscar.toLowerCase());
  })
  .sort(compare);
}
},

mounted () {
this.buscar();
},

}
</script>




<style>
/*#app {

}*/

.bg_menu{
background: #1387ce;
}

.logo{
width: 50px; 
margin-right: 10px;
}

.hover_nosotros:hover{
color:beige !important;
}

.hover_inicio:hover{
color:beige !important;
}

.hover_servicios:hover{
color:beige !important;
}

.hover_stack:hover{
color:beige !important;
}

.hover_noticias:hover{
color:beige !important;
}

.input_buscar{
border-radius: 0;
}

.bg_lupa{
background: #342c64 ;
cursor: pointer;
}

.bg_footer{
background: #342c64 ;
}

.menu_border{
border-bottom: 1px solid white !important ;
}
/*
.btn_ambur{
    border:1px solid white;
}
.icono_bars{
    display: inline-block;
    margin: 5px;
    color: white;
}

.icono_x{
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 6.5px;
    margin-left: 6.5px;
    color: white;
}
*/
.border_lista_inicio, .border_lista_nosotros, .border_lista_servicios, .border_lista_stack {
    border-bottom: 1px solid white;
}


.navbar-toggler {
        border: 0 !important;
    }
    
    .navbar-toggler:focus,
    .navbar-toggler:active,
    .navbar-toggler-icon:focus {
        outline: none !important;
        box-shadow: none !important;
        border: 0 !important;
    }
    
    /* Lines of the Toggler */
    .toggler-icon{
        width: 35px;
        height: 4px;
        background-color: white;
        display: block;
        border-radius:8px;
        transition: 0.4s ease;
    }
    
    /* Adds Space between the lines */
    .middle-bar{
        margin: 6px 0;
    }
    
    /* State when navbar is opened (START) */
    .navbar-toggler .top-bar {
        transform: rotate(-45deg) translate(-9px,6px);
    }
    
    .navbar-toggler .middle-bar {
        opacity: 0;
        filter: alpha(opacity=0);
    }
    
    .navbar-toggler .bottom-bar {
        transform: rotate(45deg) translate(-8px,-6px);
    }
    /* State when navbar is opened (END) */
    
    /* State when navbar is collapsed (START) */
    .navbar-toggler.collapsed .top-bar {
        transform: rotate(0);
    }
    
    .navbar-toggler.collapsed .middle-bar {
        opacity: 1;
        filter: alpha(opacity=100);
    }
    
    .navbar-toggler.collapsed .bottom-bar {
        transform: rotate(0);
    }
</style>
