<template>
  <Componente_inicio></Componente_inicio>
</template>

<script>
// @ is an alias to /src
import Componente_inicio from '@/components/inicio/Componente_inicio'

export default {
  name: 'Inicio',
  components: {
    Componente_inicio
  }
}
</script>
