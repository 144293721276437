<template>
<div class="container">
 <center>
  <div class="d-inline-flex p-2 bd-highlight" v-for="vide in videos" :key="vide.video" style="width:310px; ">
            <div class="card" style="width: 18rem; border:1px solid white !important">
                   
              <video class="videos bg-info" height="155" id="miVideo" autoplay controls loop muted>
               <source v-bind:src="vide.video" type="video/mp4">
              </video> 
              <div class="card-body card_body">
                <div style="height: 50px; margin-top: 2px;">
                   <h5 class="titulo_video">{{ vide.titulo_video }}</h5>
                     <p class="text-justify"></p>
                </div><hr/>
                  <button class="btn btn-primary btn-block btn_modal" data-bs-toggle="modal" data-bs-target="#modalVideos" @click="video_escogido(vide.id), modal_video(1)"><i class="fas fa-eye"></i> Detalles</button>
                </div>
            </div>
  </div>
</center>
</div>


<!-- Modal -->
<div class="modal fade" id="modalVideos" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalVideosLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="modalVideosLabel">TechDevsPerú</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="modal_video(0)"></button>
      </div>
      <div class="modal-body">
        <!--<div v-for="vide in videos" :key="vide.video" v-show="videoEscogido == vide.id">
            <div class="row">
               <div class="col-md-9">
                <video class="videos bg-info img-fluid" autoplay controls loop muted v-if="bool_video_modal == 1">
                   <source v-bind:src="vide.video" type="video/mp4">
                </video> 

                <div v-if="bool_video_modal == 0"></div> 
               </div>
               <div class="col-md-3">
                  <h3>{{ vide.titulo_video }}</h3> <hr/>
                  <p class="text_justify">
                    {{ vide.desripcion_video }}
                  </p> <hr/>
                  <p>
                    {{ vide.fecha_video }}
                  </p>
               </div>
            </div>
        </div>-->
        <div v-for="vide in videos" :key="vide.video">
          <div class="row">
            <div class="col-md-9" v-if="videoEscogido == vide.id">
              <div v-if="bool_video_modal == 1">
                <video class="videos bg-info img-fluid" autoplay controls loop>
                   <source v-bind:src="vide.video" type="video/mp4">
                </video>
              </div>
              <div v-if="bool_video_modal == 0">
                <video class="videos bg-info img-fluid" autoplay controls loop muted>
                   <source v-bind:src="vide.video" type="video/mp4">
                </video>
              </div>
            </div>
            <div class="col-md-3" v-if="videoEscogido == vide.id">
                  <h3>{{ vide.titulo_video }}</h3> <hr/>
                  <p class="text_justify">
                    {{ vide.desripcion_video }}
                  </p> <hr/>
                  <p>
                    {{ vide.fecha_video }}
                  </p>
               </div>
            </div>
        </div>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>

</template>

<script>
import axios from "axios";

export default {
    name: 'Componente_video',

    data(){
        return{
            videoEscogido:'',
            bool_video_modal:'',
            videos:[
                {
                  id:1, 
                  video:require("@/assets/video1.mp4"), 
                  titulo_video:"Partes de una Página Web", 
                  desripcion_video:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestiae architecto accusantium, odit cumque cupiditate quod distinctio enim doloribus natus esse vero. At illum aut itaque ex explicabo molestias, doloribus temporibus.", 
                  fecha_video:"05/09/22"
                },

                {
                  id:2, 
                  video:require("@/assets/video2.mp4"), 
                  titulo_video:"Importancia de los Formularios en la Web", 
                  desripcion_video:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestiae architecto accusantium, odit cumque cupiditate quod distinctio enim doloribus natus esse vero. At illum aut itaque ex explicabo molestias, doloribus temporibus.", 
                  fecha_video:"05/09/22"
                },

                {
                  id:3, 
                  video:require("@/assets/video3.mp4"), 
                  titulo_video:"Razones para tener un Sitio Web", 
                  desripcion_video:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestiae architecto accusantium, odit cumque cupiditate quod distinctio enim doloribus natus esse vero. At illum aut itaque ex explicabo molestias, doloribus temporibus.", 
                  fecha_video:"05/09/22"
                },

                {
                  id:4, 
                  video:require("@/assets/video4.mp4"), 
                  titulo_video:"¿Qué necesitas para tener un Sitio Web?", 
                  desripcion_video:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestiae architecto accusantium, odit cumque cupiditate quod distinctio enim doloribus natus esse vero. At illum aut itaque ex explicabo molestias, doloribus temporibus.", 
                  fecha_video:"05/09/22"
                },

                {
                  id:5, video:require("@/assets/video5.mp4"), 
                  titulo_video:"¿Qué es una Página Web?", 
                  desripcion_video:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestiae architecto accusantium, odit cumque cupiditate quod distinctio enim doloribus natus esse vero. At illum aut itaque ex explicabo molestias, doloribus temporibus.¿Qué es una Página Web?", 
                  fecha_video:"05/09/22"
                },

                {
                  id:6, 
                  video:require("@/assets/video3.mp4"), 
                  titulo_video:"Razones para tener un Sitio Web", 
                  desripcion_video:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestiae architecto accusantium, odit cumque cupiditate quod distinctio enim doloribus natus esse vero. At illum aut itaque ex explicabo molestias, doloribus temporibus.", 
                  fecha_video:"05/09/22"
                },
            ]
        }
    },

    methods: {
        video_escogido(videoId) {
            this.videoEscogido = videoId;
        },

        modal_video(bool_video){
          this.bool_video_modal = bool_video
        }
    },
}
</script>

<style>
.contenedor_videos{
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
}


.card_body{
  border-bottom: 1.5px solid #00000045 !important;
  border-right: 1.5px solid #00000045 !important;
  border-left: 1.5px solid #00000045 !important;
}

.text_justify{
  text-align: justify;
}

.titulo_video{
  padding-bottom: 20px;
}

.btn_modal{
  background: #342c64;
  border: 1px solid #342c64;
  padding: 5px 35px 5px 35px;
}
</style>