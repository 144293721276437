<template>

  <nav class="navbar navbar-expand-lg fixed-top bg_menu pb-2 pt-2 menu_border modo_pc" v-for="pagina in str_paginas" :key="pagina.str_url1">
  <div class="container">
    <router-link to="/" class="nav-link text-white" @click="home()"><img src="./assets/td.png" class="logo"></router-link>

    <div class="collapse navbar-collapse " id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0 fs-5">
        <li class="nav-item">
          <router-link 
           v-bind:to="pagina.str_url1" 
           :class="['nav-link text-white hover_inicio']"
           >{{ pagina.seccion1 }}</router-link>
        </li>

        <li class="nav-item">
          <router-link 
          v-bind:to="pagina.str_url2"   
          :class="['nav-link text-white hover_nosotros', nosotros_bold]" 
          @click="nosotros()">{{ pagina.seccion2 }}</router-link>
        </li>

        <li class="nav-item">
          <router-link 
          v-bind:to="pagina.str_url3" 
          :class="['nav-link text-white hover_servicios', servicios_bold]" 
          @click="servicios()">{{ pagina.seccion3 }}</router-link>
        </li>

        <li class="nav-item">
          <router-link 
          v-bind:to="pagina.str_url4" 
          :class="['nav-link text-white hover_stack', stack_bold]" 
          @click="stack()">{{ pagina.seccion4 }}</router-link>
        </li>

        <li class="nav-item">
          <router-link 
          v-bind:to="pagina.str_url5"  
          :class="['nav-link text-white hover_noticias', noticias_bold]" 
          @click="noticias()">{{ pagina.seccion5 }}</router-link>
        </li>

      </ul>
      <form class="d-flex dropdown" role="search" v-on:submit.prevent>
        <input type="search" class="form-control input_buscar" placeholder="¿Qué buscamos hoy?" data-bs-toggle="modal" data-bs-target="#buscadorInternoModal">
        <span class="input-group-text bg_lupa"  data-bs-toggle="modal" data-bs-target="#buscadorInternoModal"><i class="fas fa-search text-white"></i></span>
      </form>
    </div>
  </div>
</nav>
      <!-- Button trigger modal -->

<!-- Modal -->
<div class="modal fade" id="buscadorInternoModal" tabindex="-1" aria-labelledby="buscadorInternoModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="buscadorInternoModalLabel">TechDevsPeru</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <input class="form-control me-2" v-model="str_buscar" type="search" placeholder="¿Qué bucamos hoy?" aria-label="Buscar">
      <ul class="list-group list-group-flush">
        <li class="list-group-item" v-for="opcion in filteredAndSorted" :key="opcion.buscador" v-show="str_buscar !='' ">
            <router-link v-bind:to="opcion.buscador" :class="['nav-link']"><i class="fas fa-search"></i> {{ opcion.buscador }}</router-link>
        </li>
      </ul>
      </div>
      <div class=" bg_footer">
         <div class="row  mt-3 mb-3">
           <div class="col-md-5 text-white text-center"><i class="fab fa-whatsapp"></i> 948 619 212</div>
           <div class="col-md-7 text-white text-center"><i class="fas fa-envelope"></i> informes@techdevsperu.com</div>
         </div>
      </div>
    </div>
  </div>
</div>

<div class="modo_celular">
  <Componente_cabcera_movil></Componente_cabcera_movil>
</div>

  <router-view/>

  <Componente_footer></Componente_footer> 

</template>

<script>
import axios from "axios";
import Componente_cabcera_movil from '@/components/cabecera/Componente_cabcera_movil.vue'
import Componente_footer from "./components/footer/Componente_footer.vue";

export default {
  name: 'App',
  components:{
    Componente_footer,
    Componente_cabcera_movil,
    
  },

  data(){
    return{
      inicio_bold:'',
      nosotros_bold:'',
      servicios_bold:'',
      stack_bold:'',
      noticias_bold:'',

      str_paginas:[
      {
        str_url1:"inicio",
        str_url2:"nosotros",
        str_url3:"servicios",
        str_url4:"stack",
        str_url5:"noticias",
        
        seccion1: "INICIO",
        seccion2: "NOSOTROS",
        seccion3: "SERVICIOS",
        seccion4: "STACK",
        seccion5: "NOTICIAS"
      
      }
      ],
////
      str_buscar:'',
      str_buscador:[
      {buscador:"inicio"},
      {buscador:"nosotros"},
      {buscador:"servicios"},
      {buscador:"stack"},
      {buscador:"noticias"}
      ],
    }
  },
  methods: {

    buscar() {
      console.log(this.str_buscador);
        },

    home(){
      this.inicio_bold = '';
      this.nosotros_bold = '';
      this.servicios_bold = '';
      this.stack_bold = '';
      this.noticias_bold = '';
    },

    inicio() {
      this.inicio_bold = 'fw-bold';
      this.nosotros_bold = '';
      this.servicios_bold = '';
      this.stack_bold = '';
      this.noticias_bold = '';
    },

    nosotros() {
      this.inicio_bold = '';
      this.nosotros_bold = 'fw-bold';
      this.servicios_bold = '';
      this.stack_bold = '';
      this.noticias_bold = '';
    },

    servicios() {
      this.inicio_bold = '';
      this.nosotros_bold = '';
      this.servicios_bold = 'fw-bold';
      this.stack_bold = '';
      this.noticias_bold = '';
    },

    stack() {
      this.inicio_bold = '';
      this.nosotros_bold = '';
      this.servicios_bold = '';
      this.stack_bold = 'fw-bold';
      this.noticias_bold = '';
    },

    noticias() {
      this.inicio_bold = '';
      this.nosotros_bold = '';
      this.servicios_bold = '';
      this.stack_bold = '';
      this.noticias_bold = 'fw-bold';
    }
  },

  computed: {
    filteredAndSorted() {//buscador
      const compare = (a, b) => {
      if (a.buscador < b.buscador) return -1;
      if (a.buscador > b.buscador) return 1;
      return 0;
    };
    return this.str_buscador.filter((opcion) => {
        return opcion.buscador.toLowerCase().includes(this.str_buscar.toLowerCase());
      })
      .sort(compare);
    }
  },

  mounted () {
    this.buscar();
  },

}
</script>

<style>
/*#app {
 
}*/

.bg_menu{
  background: #1387ce;
}

.logo{
  width: 50px; 
  margin-right: 10px;
}

.hover_nosotros:hover{
  color:beige !important;
}

.hover_inicio:hover{
  color:beige !important;
}

.hover_servicios:hover{
  color:beige !important;
}

.hover_stack:hover{
  color:beige !important;
}

.hover_noticias:hover{
  color:beige !important;
}

.input_buscar{
  border-radius: 0;
}

.bg_lupa{
  background: #342c64 ;
  cursor: pointer;
}

.bg_footer{
  background: #342c64 ;
}

.menu_border{
  border-bottom: 1px solid white !important ;
}


@media screen and (min-width: 701px) {/*supera los 700 px */
  .modo_celular{
      display: none;
  }

  .modo_pc{
    display: block;
  }
}

@media screen and (max-width: 700px) {/*maximo llega a 700 px */
  .modo_celular{
      display: block;
  }

  .modo_pc{
    display: none;
  }
}
</style>
