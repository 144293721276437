<template>
  <br/><br/>
      <div class="container text-center">
        <h2>STACK TECNOLOGICO</h2>
      <div class="tecno_pc">
        <div class="d-inline-flex p-2 bd-highligh" v-for="(tecno, index) in tecnologias" :key="tecno.tecnologias" style="width:310px; ">
            <div class="card" style="width: 18rem; border:1px solid white !important">
                   <img :src="tecno.tecno" class="img-fluid img_tecno" data-bs-toggle="modal" data-bs-target="#exampleModalTecnonlogias" @click="tecnologia_escogida(index)">
                <div class="card-body card_body bg-ligth">
                  <div style="height: 50px; margin-top: 2px;">
                   <!--<h5 class="titulo_video">{{ tecno.nomabre_tecno }}</h5>-->
                   <p class="text-center">
                    {{ tecno.descripcion_tecno.substring(0,40) + "..." }}
                   </p>
                </div><hr/>
                  <button class="btn btn-primary btn-block btn_modal" style="border-radius: 0px;" data-bs-toggle="modal" data-bs-target="#exampleModalTecnonlogias" @click="tecnologia_escogida(index)"><i class="fas fa-eye"></i> Ver más</button>
                </div>
            </div>
        </div>
      </div>

      <div class="tecno_movil">
        <div class="d-inline-flex p-2 bd-highlight mb-3" v-for="(tecno, index) in tecnologias" :key="tecno.tecnologias" style="width:310px; ">
            <div class="card" style="width: 18rem; border:1px solid white !important">
                   <img :src="tecno.tecno" class="img-fluid img_tecno" data-bs-toggle="modal" data-bs-target="#exampleModalTecnonlogias" @click="tecnologia_escogida(index)">
                <div class="card-body card_body bg-ligth">
                  <button class="btn btn-primary btn-block btn_modal" style="border-radius: 0px;" data-bs-toggle="modal" data-bs-target="#exampleModalTecnonlogias" @click="tecnologia_escogida(index)"><i class="fas fa-eye"></i> Ver más</button>
                </div>
            </div>
        </div>
      </div>

      
      </div>


<!-- Modal -->
<div class="modal fade" id="exampleModalTecnonlogias" tabindex="-1" aria-labelledby="exampleModalTecnonlogiasLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalTecnonlogiasLabel">{{ str_nombre_tecno }}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        
<div class="card mb-3" >
  <div class="row g-0">
    <div class="col-md-7">
      <img :src="str_direccion_tecno" class="img-fluid rounded-start" alt="...">
    </div>
    <div class="col-md-5">
      <div class="card-body">
        <h5 class="card-title border-bottom">Descrpción</h5>
        <p class="card-text descrip_tecno">{{ str_descripcion_tecno }}</p>
      </div>
    </div>
  </div>
</div>
      </div>
      <div class=" bg_footer_stack">
         <div class="row  mt-3 mb-3">
           <div class="col-md-5 text-white text-center"><i class="fab fa-whatsapp"></i> 948 619 212</div>
           <div class="col-md-7 text-white text-center"><i class="fas fa-envelope"></i> informes@techdevsperu.com</div>
         </div>
      </div>
    </div>
  </div>
</div>


    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
  <button class="btn btn-primary me-md-2 d-none" type="button" @click="mostrar()"><i class="fas fa-eye"></i>Botón</button>
</div>
</template>

<script>
import axios from "axios";

export default {
    name:'Componente_stack',

    data(){
        return{
          str_descripcion_tecno:"",
          str_direccion_tecno:"",
          str_nombre_tecno:"",
          tecnologias:[
            {
              id:"1",
              tecno:require("@/assets/tecnologias/ecmascript.jpg"),
              nomabre_tecno:"ECMAScript",
              descripcion_tecno:"Cuando hablamos de ECMAScript, nos referimos al estándar que ya va por la versión ES6 y  determina cómo emplear el lenguaje Javascript, que permite a los fabricantes de software desarrollar las herramientas adecuada para interpretarlo correctamente."
            },

            {
              id:"2",
              tecno:require("@/assets/tecnologias/php.jpg"),
              nomabre_tecno:"PHP",
              descripcion_tecno:"La sigla PHP identifica a un lenguaje de programación que nació como Personal Home Page (PHP) Tools. Fue desarrollado por el programador de origen danés Rasmus Lerdorf en 1994 con el propósito de facilitar el diseño de páginas web de carácter dinámico."
            },

            {
              id:"3",
              tecno:require("@/assets/tecnologias/python.jpg"),
              nomabre_tecno:"Python",
              descripcion_tecno:" Se trata de un veterano lenguaje de programación presente en multitud de aplicaciones y sistemas operativos. Podemos encontrarlo corriendo en servidores, en aplicaciones iOS, Android, Linux, Windows o Mac."
            },

            {
              id:"4",
              tecno:require("@/assets/tecnologias/java.jpg"),
              nomabre_tecno:"Java",
              descripcion_tecno:"Java es un lenguaje de programación, además de una excelente plataforma informática que es indispensable para el correcto funcionamiento de las diversas páginas web existentes en la actualidad, así como también para las aplicaciones de dispositivos Smartphone o computadoras. "
            },

            {
              id:"5",
              tecno:require("@/assets/tecnologias/jquery.jpg"),
              nomabre_tecno:"JQuery",
              descripcion_tecno:"JQuery es una librería perteneciente al lenguaje de programación JavaScript, uno de los más usados en materia de desarrollo web, de código abierto y con la capacidad de mejorar la interactividad de una página web. Es algo que facilita enormemente la tarea de desarrollo y diseño de páginas web."
            },

            {
              id:"6",
              tecno:require("@/assets/tecnologias/vue.jpg"),
              nomabre_tecno:"Vue js",
              descripcion_tecno:"Vue (pronunciado /vjuː/, como view) es un framework progresivo para construir interfaces de usuario. A diferencia de otros frameworks monolíticos, Vue está diseñado desde cero para ser utilizado incrementalmente."
            },

            {
              id:"7",
              tecno:require("@/assets/tecnologias/angular.jpg"),
              nomabre_tecno:"Angular",
              descripcion_tecno:"Angular es un framework opensource desarrollado por Google para facilitar la creación y programación de aplicaciones web de una sola página, las webs SPA (Single Page Application)."
            },
          ]
        }
    },

    methods: {
    mostrar() {
      axios.get('https://jsonplaceholder.typicode.com/todos')
      .then((respuesta) =>{
        console.log(respuesta.data);
      })
      .catch((e) =>{
        console.log(e);
      })
    },

    tecnologia_escogida(index){
      this.str_descripcion_tecno = this.tecnologias[index].descripcion_tecno;
      this.str_direccion_tecno = this.tecnologias[index].tecno;
      this.str_nombre_tecno = this.tecnologias[index].nomabre_tecno;
      
    }
  },
}

</script>

<style>


.img_tecno{
  cursor: pointer;
}

@media screen and (min-width: 701px) {/*supera los 700 px modo pc*/
.tecno_pc{
  text-align: center;
  display: block;
}

.tecno_movil{
  display: none;
}
}

@media screen and (max-width: 700px) {/*maximo llega a 700 px modo movil*/
  .tecno_movil{
  display: block;
}

.tecno_pc{
  display: none;
}

}


.bg_footer_stack{
background: #342c64 ;
}
</style>