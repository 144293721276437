<template>
    <h2 class="text-center mt-5 mb-5">El Equipo</h2>
<div class="container">

    <div class="row text-center">
    <div class="col-md-4">
      <img src="../../assets/avt_hector.png" class="img-fluid avatar" />
      <h4>Héctor Pérez</h4>
      <p class="card-text">Administración de servidores Linux, analisis y diseño de bases de datos, desarrollo con tecnologias como PHP, ReactJS.</p> <hr/>
    </div>
    <div class="col-md-4">
      <img src="../../assets/avt_piero.jpeg" class="img-fluid avatar" />
      <h4>Piero Cóndor</h4>
      <p class="card-text">Desarrollo de sistemas administrativos, sitios web, aplicaciones web con tecnologías PHP, Javascript, Java, Vue, Quasar.</p> <hr/>
    </div>
    <div class="col-md-4">
      <img src="../../assets/avt_victor.png" class="img-fluid avatar" />
      <h4>Victor Huayhuapuma</h4>
      <p class="card-text">Desarrollo de interfaces de usuario en sistios webs usando tecnologias como JavaScript, Vue Js, React Js, Ajax, Quasar, Bootstrap.</p> <hr/>
    </div>
  </div>

  <div class="row bg-dark text-white">
    <div class="col-md-6 p-4"> 
      <h5 class="text-center text-info">¿QUÍENES SOMOS?</h5>
    <hr>
      <p class="text_justify">
        Somos un emprendimiento en el campo de la informática integrado por desarrolladores con experiencia en el creación de soluciones web, soluciones móviles y demás proyectos que den valor agregado a los proyectos de nuestros clientes.      
      </p>
    </div>
    <div class="col-md-6 p-4"> 
      <h5 class="text-center text-info">QUÉ HACEMOS?</h5>
    <hr>
      <p class="text_justify">
        Realizamos todos los aspectos del proceso de desarrollo de software, incluyendo tus estrategias de información y negocio. Diseñamos, desarrollamos y brindamos soporte a tus productos de software.      
      </p>
    </div>
  </div>

  <div class="row text-white">
    <div class="col-md-6 p-4 bg_mision">
      <h5 class="text-center text-white">MISIÓN</h5><hr/>
      <p class="text_justify">
        Promover el desarrollo y la innovacion tecnologica en nuestros clientes brindando soluciones acorde a sus necesidades para el cumplimiento de sus objetivos.
      </p>
    </div>
    <div class="col-md-6 p-4 bg_vision">
      <h5 class="text-center text-white">VISIÓN</h5><hr/>
      <p class="text_justify">
        Ser una empresa reconocida a nivel nacional por la contribución al progreso tecnologico y por el desarrollo de soluciones a largo plazo.
      </p>
    </div>
  </div>

  <div class="row bg-dark text-white text-center">
    <div class="col-md-3 p-4"><br/>
      <h5 class="text-info">VALORES</h5>
    </div>
    <div class="col-md-3 p-4">
      <div class="mi_borde">
      <img src="../../assets/trabajo_equipo.jpg" class="img-fluid mb-3" alt="..." />
      <h4 class="text-info">Trabajo en equipo</h4><hr/>
        <p class="valores">
          Promover la competitividad a traves de un grupo humano comprometido en el desarrollo de diversos proyectos.
        </p>
      </div>
      </div>

        <div class="col-md-3 p-4">
          <div class="mi_borde">
            <img src="../../assets/innovadores.jpg" class="img-fluid mb-3" alt="..." />
         <h4 class="text-info">Innovación</h4><hr/>
          <p class="valores">
            Impulsar ideas plasmándolas en proyectos que brinden mejores soluciones al cliente.
          </p>
          </div>

       </div>

       <div class="col-md-3 p-4">
        <div class="mi_borde">
                      <img src="../../assets/responsabilidad.jpg" class="img-fluid mb-3" alt="..." />
         <h4 class="text-info">Responsabilidad</h4><hr/>
          <p class="valores">
            Consolidar el crecimiento profesional mediante el cumplimiento de las actividades en los plazos establecidos.
          </p>
        </div>

       </div>

    </div>
  


</div>
</template>

<script>

export default {
    name:'Comp_nosotros_movil',

    data(){
        return{

        }
    },
    methods: {
        name() {
            
        }
    },
}

</script>

<style>
.text_justify{
  text-align: justify;
}

.bg_vision, .bg_mision{
  background: #03449f;
}

.mi_borde{
  border:1px solid grey;
}

.valores{
  padding-right: 20px;
  padding-left: 20px;
  text-align: justify;
}
</style>