<template>
    <div class="bg-primary text-white">
                <marquee id="scroll_news">
            <div v-for="noti, index in noticias" :key="noti.titulo_noticia" class="contenedor_marque_texto" >
                <div @click="irNoticia(), noticia(index), acction_expandirNoticias()" v-on:mouseover="mOver()" v-on:mouseout="mOut()" style="cursor:pointer" class="marque_texto fw-bold" data-bs-toggle="modal" data-bs-target="#exampleModal"> 
                    {{ noti.titulo_noticia }} 
                </div>
            </div>
        </marquee>
    </div>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title titulo_noicia" id="exampleModalLabel">{{ $store.state.str_titulo_noticia }}</h2>
       <i class="fas fa-2x fa-times btn_modal_x" data-bs-dismiss="modal" aria-label="Close"></i>
      </div>
      <div class="modal-body">
        <div class="d-flex flex-column mb-3">
           <div class="row">
            <div class="col-md-8 contenedor_noticia_text">
                <div class="bg-light p-3">
                   <p class="text_justify">{{ $store.state.str_noticia }}</p>
                   <p class="text-end">{{ $store.state.str_autor_noticia }}</p> 
                   <p class="text-end">{{ $store.state.str_fecha_noticia }}</p>
                </div>
            </div>
            <div class="col-md-4 contenedor_noticia_img">
                <img :src="$store.state.str_imagen_noticia" class="img-fluid">
            </div>
        </div>
        </div>    

      </div>
      <div class="bg_noticia_footer">
         <div class="row  mt-3 mb-3">
           <div class="col-md-5 text-white text-center"><i class="fab fa-whatsapp"></i> 948 619 212</div>
           <div class="col-md-7 text-white text-center"><i class="fas fa-envelope"></i> informes@techdevsperu.com</div>
         </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import axios from 'axios';
import { mapActions } from 'vuex';

export default {
    name:"Componente_marquesina",

    data(){
        return{
            noticias:[
            {
              id:"1",
              titulo_noticia:"Treinta años de Linux",
            },

            {
              id:"2",
              titulo_noticia:"¿Qué se necesita para tener una web?",
            },
            {
              id:"3",
              titulo_noticia:"Desarrollo backend",
            },

            {
              id:"4",
              titulo_noticia:"Lorem",
            }


          ]
        }
    }
    ,
    methods: {
        mOver(){
            
            document.getElementById('scroll_news').stop();
        },
        mOut(){
            document.getElementById('scroll_news').start();
        },
        irNoticia(){
            this.$router.push({ path: `/noticias` })
        },
        noticia(index){
            this.$store.state.int_IndexNoticia_escogida = index;
            console.log(this.$store.state.int_IndexNoticia_escogida);
        },

      ...mapActions([
        'acction_expandirNoticias'
      ])
    }
}
</script>


<style>
.bg_marque_barra{
    background: #342c64 ;
}

.contenedor_marque_texto{
    display: inline-block;
}

.marque_texto{
    margin-right: 20px;
    font-size: 18px;
}

.contenedor_noticia_text{
        display:flex;
        justify-content: center;
        align-items: center;
        border-left: 3px solid rgb(0, 174, 255);
    }

    .contenedor_noticia_img{
      display:flex;
        justify-content: center;
        align-items: center;
    }

    .text_justify{
        text-align: justify;
    }

    .btn_modal_x{
        cursor: pointer;
    }

    .bg_noticia_footer{
       background: #342c64 ;
    }
</style>