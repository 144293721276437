<template>
  <Componente_stack></Componente_stack>
</template>

<script>
import Componente_stack from '@/components/estack/Componente_stack';
export default {
  name:'Stack',

  components:{
    Componente_stack
  },

  data(){
    return{

    }
  },


}
</script>