<template>
  <Componente_servicios></Componente_servicios>
</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue'
import Componente_servicios from '@/components/servicios/Componente_servicios.vue';

export default {
  name: 'Servicios',
  components: {
    HelloWorld,
    Componente_servicios
  },
}
</script>