<template>
    <br><br/><br/>
<div class="container">

<button class="btn btn-primary" @click="abrirCerrarListaSideBar()"><i class="fas fa-file"></i> NOTICIAS</button>

<div :class="[sidebarNoticia ? 'offcanvas offcanvas-start' : 'offcanvas offcanvas-start show']" tabindex="-1" id="offcanvas" aria-labelledby="offcanvasLabel">
  <div class="offcanvas-header bg-info text-white">
    <h5 class="offcanvas-title" id="offcanvasLabel">TECHDEVSPERÚ NOTICIAS</h5> <hr>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" @click="abrirCerrarListaSideBar()"></button>
  </div>
  <div class="offcanvas-body pdding_canvas">
    <ul class="list-group list-group-flush ">
      <li class=" " v-for="noti, index in $store.state.noticias" :key="noti.noticia">
        <a class="lista_noticia sidebar_lista_noticia list-group-item list-group-item-action p-3" :href="'#'+index" @click="expandirNoticiaSubMenu(index), abrirCerrarListaSideBar()">{{ index + 1 }} - {{ noti.titulo_noticia }}</a>
      </li>
    </ul>
  </div>
</div>


<hr/>
<div class="d-flex flex-column mb-3">
  <div v-for="noti, index in $store.state.noticias" :key="noti.noticia">
    <div class="row">
        <div class="col-md-8 contenedor_noticia_text" >
           <div :class="[ noti.estado ? 'd-block' : 'd-none']">
            <h2 class="titulo_noicia">{{ noti.titulo_noticia }}</h2>
            <p class="texto_noticia">{{ noti.noticia.substring(0,400) }} <span class="text-info verMasMenos" @click="expandiNoticia(index)"><u>Ver mas</u></span></p>
            <p class="text-end">{{ noti.autor_noticia }}</p> 
            <p class="text-end">{{ noti.fecha_noticia }}</p>
          </div>
          <div :class="[ noti.estado ? 'd-none' : 'd-block bg-light']">
            <h2 class="titulo_noicia" :id="index">{{ noti.titulo_noticia }}</h2>
            <p class="texto_noticia">{{ noti.noticia }} <span class="text-danger verMasMenos" @click="expandiNoticia(index)"><u>Ver menos</u></span></p>
            <p class="text-end">{{ noti.autor_noticia }}</p> 
            <p class="text-end">{{ noti.fecha_noticia }}</p>
          </div>        
        </div>
        <div class="col-md-4 contenedor_noticia_img" style="padding-right: 0px;">
          <img v-bind:src="noti.imagen_noticia" alt="img_notica" style="width: 200px; height: 200px;">
        </div>
    </div><!--fin del row--><br/><br/><br/>
  </div>
</div>

<div class="row">
  <div class="col-4"></div>
  <div class="col-4">
      <div id="btnSubir" @click="irArriba()">
       <i class="fas fa-chevron-circle-up fa-3x"></i>
    </div> 
  </div>
  <div class="col-4"></div>
</div>

{{ $store.state.int_IndexNoticia_escogida  }}

</div>

</template>
    
<script>
import axios from "axios";
import { mapActions } from 'vuex';

export default {
    name: 'Componente_noticias',

    data(){
        return{
          str_noticia:'',
          sidebarNoticia: true, 
          
        }
    },

    methods: {
      expandiNoticia(index){//expande noticia escogida desde la seccion noticias
        this.$store.state.noticias[index].estado = !this.$store.state.noticias[index].estado;
        console.log(index);
      },

      expandirNoticiaSubMenu(index){//al dar click en el submenu la noticia se expande
        this.$store.state.noticias[index].estado = false;
      },

      expandirNoticiaMarquesina() { //expande la noticia escogida desde la marquesina    
          this.$store.state.noticias.map((item, i) =>{
          if (this.$store.state.int_IndexNoticia_escogida == i) {
            item.estado = !item.estado;

            this.str_noticia = item.noticia
            console.log(item.estado +" - " + i);
          } 
        })
      },

      irArriba(){
            window.scrollTo({ top: 0, behavior: 'smooth' });
          },

      abrirCerrarListaSideBar(){//abre el sidebar
        this.sidebarNoticia = !this.sidebarNoticia;
        //console.log(this.sidebarNoticia);
        this.$store.state.int_disabled_btn_form = !this.$store.state.int_disabled_btn_form ;
          },
    },

    created () {
     this.expandirNoticiaMarquesina();
    },
}
</script>
    <style>
      .contenedor_noticia_text{
        display:flex;
        justify-content: center;
        align-items: center;
        border-left: 3px solid rgb(0, 174, 255);
    }

    .contenedor_noticia_img{
      display:flex;
        justify-content: center;
        align-items: center;
    }

    .verMasMenos{
      cursor: pointer;
    }

    .texto_noticia{
      text-align: justify;
    }

    .titulo_noicia{
      color: rgb(0, 174, 255);
    }

    /*#btnSubir{
      cursor:pointer;
      color:rgb(12, 12, 37);
    }*/

    #btnSubir {

	color:rgb(0, 145, 255); /* Color de fondo */
	position: fixed;
	bottom: 40px;
	cursor: pointer;
	z-index: 99;
}

#btnSubir:hover{
  color: blue;
}

.lista_noticia{
  text-decoration: none;
  color: black;
  padding:0px;
}
.sidebar_lista_noticia {
    border: 1px solid white;
    border-bottom: 1px solid rgb(222, 219, 219);
}

.sidebar_lista_noticia:hover {
   background: rgb(5, 187, 238);
   color: white;
   font-weight: bold;
}

.pdding_canvas{
  padding: 0px;
}
</style>